var headerHeight = document.querySelector("header").clientHeight;
var footerHeight = document.querySelector("footer").offsetHeight;
var viewportHeight = window.innerHeight;

document.querySelector(".contentwrapper").style.marginTop = headerHeight + "px";
document.querySelector(".contentwrapper").style.minHeight = viewportHeight - headerHeight - footerHeight + "px";


// window.onload = function () {
// 	scrollactions.init();
// }

window.onresize = function () {
	var headerHeight = document.querySelector("header").clientHeight;
	var footerHeight = document.querySelector("footer").offsetHeight;
	var viewportHeight = window.innerHeight;

	document.querySelector(".contentwrapper").style.marginTop = headerHeight + "px";
	document.querySelector(".contentwrapper").style.minHeight = viewportHeight - headerHeight - footerHeight + "px";
};

